export default {
  article_17_title: "Extras - aplicación móvil",
  article_18_title: "Extras - aplicación móvil - personalización",
  article_19_title: "Extras - pagos con tarjeta",
  wb_hide_booking_employees: "Ocultar empleados al reservar",
  wb_og_image_url: "Gráficos de URL de gráfico abierto (y: imagen)",
  wb_favicon_url: "URL de icono favorito",
  wb_elevation: "Sombra",
  wb_show_sign_in_button: "Mostrar el botón 'Iniciar sesión'",
  wb_show_sign_up_button: "Mostrar botón 'Crear cuenta'",
  wb_show_active_button: "Mostrar botón activo",
  wb_show_company_name: "Mostrar nombre de la empresa",
  wb_button_size_type_x_small_title: "XS",
  wb_button_size_type_small_title: "S",
  wb_button_size_type_medium_title: "M",
  wb_button_size_type_large_title: "L",
  wb_button_size_type_x_large_title: "XL",
  wb_button_size_title: "Tamaño del botón",
  auth: "Autorización",
  booking_created_no_notifications_label:
    "No informes sobre la creación de la visita (sin notificaciones)",
  booking_updated_no_notifications_label:
    "No informar sobre el cambio/eliminación de la visita",
  booking_deleted_no_notifications_label: "No informar de la eliminación",
  booking_no_notifications_tooltip:
    "El cliente y el empleado no recibirán notificaciones por correo electrónico, SMS y avisos después de crear/cambiar la visita. En su lugar, recibirán notificaciones de tipo recordatorio (si las tienes activadas).",
  recurring_booking: "Repetir temporalmente",
  repeat_every: "Repetir cada",
  day: "Día",
  week: "Semana",
  month: "Mes",
  year: "Año",
  day_plural_1: "Día",
  day_plural_2: "Días",
  day_plural_3: "Días",
  day_plural_4: "Días",
  day_plural_5: "Días",
  week_plural_1: "Semana",
  week_plural_2: "Semanas",
  week_plural_3: "Semanas",
  week_plural_4: "Semanas",
  week_plural_5: "Semanas",
  month_plural_1: "Mes",
  month_plural_2: "Meses",
  month_plural_3: "Meses",
  month_plural_4: "Meses",
  month_plural_5: "Un mes",
  year_plural_1: "Año",
  year_plural_2: "Años",
  year_plural_3: "Años",
  year_plural_4: "Años",
  year_plural_5: "Años",
  booking_plural_1: "Reserva",
  booking_plural_2: "Reservas",
  booking_plural_3: "Reservas",
  booking_plural_4: "Reservas",
  booking_plural_5: "Reserva",
  ends_after_occurrences_1: "Terminar después",
  ends_after_occurrences_2: "Repeticiones",
  ends_at_date: "Terminar el dia",
  delete_recurring_booking_title: "Eliminar un evento recurrente",
  delete_recurring_booking_question:
    "Este evento es un evento recurrente. ¿Qué quieres eliminar?",
  delete_recurring_booking_option_1: "Solo este evento",
  delete_recurring_booking_option_2: "Este evento y todos los siguientes",
  delete_recurring_booking_option_3:
    "Todos los eventos, incluidos los que ya han tenido lugar",
  all: "Todos",
  payment_2: "Pago",
  recurring_booking_ends_info:
    "Se ha superado el número máximo de eventos temporales. Cambie los parámetros y vuelva a intentarlo.",
  payment_method_stripe: "Stripe",
  payment_method_cash: "Efectivo",
  payment_method_transfer: "Transferir",
  payment_method_card: "Tarjeta",
  payment_method_subscription: "Suscripción",
  payment_method_other: "Otro",
  booking_payment_transaction_create_title: "Pagar la reserva",
  booking_payment_transaction_remove_title: "Eliminar el pago de la visita",
  payment_transaction_transaction_id: "Número de transacción/comentario",
  invalid_time: "Hora incorrecta",
  add_new_day: "Añadir un nuevo día",
  max_people_title: "Número máximo de clientes al mismo tiempo con un empleado",
  wb_date_label: "Etiqueta de fecha",
  wb_start_calendar_time: "Mostrar desde la hora",
  wb_end_calendar_time: "Mostrar hasta una hora",
  wb_interval_minutes: "La duración mínima de un evento de calendario",
  minutes: "Min",
  wb_booking_note: "Información Adicional",
  wb_require_phone_number: "Solicitar un número de teléfono en el formulario",
  wb_hide_signup: "Ocultar opción de registro",
  booking_form_custom_fields: "Campos del formulario de reserva",
  booking_form_custom_fields_equal_note: "Valor esperado",
  clear_filters: "Borrar filtros",
  wb_hide_login: "Ocultar opción de inicio de sesión",
  wb_hide_header: "Ocultar el encabezado",
  wb_hide_footer: "Ocultar pie de página",
  wb_add_element: "Agregar un campo de formulario",
  wb_element_title: "Campo de formulario",
  wb_dynamic_form_element_type_1: "Cuadro de texto",
  wb_dynamic_form_element_type_2: "Campo de texto más grande",
  wb_dynamic_form_element_type_3: "Marque la casilla",
  wb_dynamic_form_element_type_4: "Botón de opción",
  wb_dynamic_form_element_type_5: "Sin correo electrónico",
  wb_dynamic_form_element_type_6: "Cabina telefónica",
  wb_dynamic_form_element_type_7: "Dirección",
  wb_dynamic_form_should_require: "Campo obligatorio",
  wb_dynamic_form_value_equals: "Valor a comprobar",
  wb_dummy_text_summary_headline: "Gracias por reservar",
  wb_summary_headline: "Título del resumen",
  wb_summary_text: "Texto resumen",
  wb_view_outlined: "Vista de conjunto",
  wb_view_flat: "Vista plana",
  wb_view_rounded: "Vista de empalme",
  wb_select_date_hour: "Texto encima del calendario",
  wb_book_now_label: "Botón de reserva",
  wb_html_code: "Código HTML",
  wb_zoom: "Ampliación",
  wb_longitude: "Longitud",
  wb_latitude: "Latitud",
  wb_map_maker_hide: "Ocultar el marcador en el mapa",
  service_location: "Ubicación",
  service_locations: "Ubicaciones",
  service_location_info:
    "La ubicación define la forma de reunirse o conectarse con los clientes. Esta información será visible en la página de confirmación de la reserva y también se incluirá en los correos electrónicos enviados. La información de la ubicación se añadirá al evento correspondiente si sincroniza Calendesk con calendarios externos. Puede añadir múltiples ubicaciones (on-line / off-line), en cuyo caso se pedirá a los clientes que hagan una elección.",
  service_location_1: "Dirección",
  service_location_2: "Teléfono",
  service_location_3: "Reunión de Google",
  service_location_4: "Diferente",
  service_location_5: "Zoom",
  service_location_6: "Microsoft Teams",
  service_location_7: "Skype",
  service_location_8: "WhatsApp",
  select_service_location_label: "Selecciona una ubicación",
  service_location_1_data_label: "Ingrese la dirección de la ubicación",
  service_location_2_data_label: "Ingresa un número de teléfono",
  service_location_3_data_label: "Ingrese información adicional",
  wb_image_margin_bottom: "Espacio entre píxeles debajo de la imagen",
  wb_text_margin_bottom: "Espacio entre píxeles debajo del texto.",
  plugins_fakturownia: "Bitfactura",
  fakturownia_description:
    "Bitfactura es un programa que te permite automatizar la emisión de facturas. Calendesk puede transferir toda la información de pago directamente a su cuenta en Fakturownia.",
  fakturownia_description_2:
    "Agregue su token de API y la dirección de su cuenta a continuación. Encontrará estos dos valores después de iniciar sesión en su cuenta en Bitfactura. Puede encontrar el token API en Configuración -> Configuración de la cuenta -> Integración -> Código de autorización API. Puede encontrar la URL de su cuenta en Configuración -> Configuración de la cuenta.",
  fakturownia_enabled_title: "Encienda el módulo Bitfactura",
  fakturownia_send_invoice_title:
    "Envía automáticamente una factura a la dirección de correo electrónico del cliente al recibir el pago.",
  fakturownia_api_token_title: "Token de API",
  fakturownia_gtu_codes_title: "Códigos GTU",
  fakturownia_account_url_title: "URL de la cuenta",
  stripe_id_label: "Identificador en Stripe",
  fakturownia_id_label: "Identificador en Bitfactura",
  private_notes_for_customer_label: "Notas privadas sobre el cliente",
  tax_label: "Impuesto",
  wants_invoice_title: "Factura",
  fakturownia_validation_error:
    "Calendesk no puede conectarse al sistema Bitfactura. Los datos proporcionados son incorrectos.",
  feature_not_available_title:
    "Lamentablemente, esta función no está disponible en su plan. Cambie su plan para desbloquear todas las oportunidades de Calendesk.",
  only_subscriptions_label:
    "Posibilidad de reservar este servicio solo para clientes con suscripción.",
  subscriptions: "Suscripciones",
  subscription: "Suscripción",
  subscriptions_list: "Lista de suscripciones",
  subscription_payments: "Pago",
  each: "Todo el mundo",
  subscription_name: "El nombre de la suscripción en Calendesk",
  subscription_name_example: "Por ejemplo, consultas mensuales",
  invoice_info_1:
    "Marque la opción de emisión de facturas si desea que el sistema emita automáticamente una factura después de una transacción de pago correcta (Requiere la configuración correcta de un complemento externo para emitir facturas con IVA, por ejemplo, Bitfactura, que se puede encontrar en los suplementos).",
  wants_invoice_select_title:
    "Emitir una factura con IVA después de recibir el pago",
  tax_amount_title: "El porcentaje de IVA que ya está incluido en el precio",
  product_in_stripe: "Producto en Stripe",
  product_price_in_stripe: "El precio del producto en Stripe",
  product_name: "El nombre del producto en Stripe",
  add_new_stripe_product_title: "Agregar un nuevo producto en Stripe",
  add_new_stripe_product_price_title:
    "Agregar un nuevo precio de producto en Stripe",
  subscription_recurring_title: "Pago recurrente",
  service_limits_label:
    "Limite el número de posibles reservas bajo esta suscripción.",
  service_limits_count_label: "Número máximo de reservas",
  service_limits_reset_time: "Restablecer el número de reservas periódicamente",
  available_services_in_subscription: "Servicios de suscripción disponibles",
  subscription_settings: "Configuración de suscripción",
  payment_settings: "Configuración de pago",
  subscriptions_settings_information:
    "Decide qué tipo de suscripción quieres ofrecer a tus clientes. Puedes elegir los servicios para los cuales se aplicará la suscripción. Nombra la suscripción de una manera que permita al cliente entender su propósito. También puedes decidir si la suscripción debe tener límites en la reserva de servicios seleccionados (por ejemplo, 10 reservas). Los límites pueden restablecerse en ciertos momentos (por ejemplo, puedes establecer 10 reservas por mes para un cliente).",
  subscription_payment_settings_information:
    "Decide cómo pagarán tus clientes la suscripción. Para ello, necesitas un producto en Stripe y la determinación de un precio, que se creará en tu cuenta de Stripe (haz clic en el icono de más para crear). El pago puede ser único o distribuido en el tiempo (por ejemplo, 100 EUR por mes). Gracias a varias combinaciones de configuraciones de suscripción y pago, puedes crear suscripciones muy complejas (por ejemplo, 10 reservas para un servicio por mes con un pago anual de 999 EUR).",
  bookings_number: "Número de reservas",
  recurrent_bookings_reset: "Restablecer temporalmente cada",
  recurrent_payment_each: "Pago periódicamente cada",
  recurrent_each: "Periódicamente cada",
  subscription_success_url: "URL de redirección después del pago correcto",
  subscription_redirect_title: "Redirecciones opcionales",
  subscription_success_url_example:
    "Por ejemplo, https://twoja-strona.pl/sukces",
  subscription_cancel_url: "Redirección de URL después de un pago incorrecto",
  subscription_cancel_url_example: "Por ejemplo, https://twoja-strona.pl/blad",
  subscription_redirect_question:
    "¿Dónde redirigir al usuario después del pago correcto o incorrecto de la suscripción?",
  booking_payment_urls_question:
    "¿Dónde redirigir al usuario después del pago correcto o incorrecto de la reserva (esta configuración solo se aplica al pago a través del sitio web en el sistema Calendesk)?",
  select_subscription: "Elige una suscripción",
  subscription_active: "La suscripción está activa",
  remove_subscription_not_possible_error_notification:
    "Las suscripciones activas no se pueden eliminar.",
  user_subscriptions: "Suscripciones",
  active: "Activo",
  inactive: "Inactivo",
  initiated: "No pagado",
  canceled: "Cancelado",
  no_user_subscriptions_info:
    "Todavía no tienes suscripciones asignadas a tus clientes. Haga clic a continuación para asignar manualmente una suscripción a su cliente o solicite a sus clientes que compren una suscripción directamente desde su sitio web.",
  go_to_subscriptions: "Ir a suscripción",
  stripe_subscription_id: " ID Stripe",
  provider_tag: "Método",
  card: "Mapa",
  cash: "Dinero",
  transfer: "Transferir",
  cancel_subscription_success:
    "Las suscripciones seleccionadas se han cancelado correctamente.",
  cancel_subscription_fail:
    "No se pudieron cancelar las suscripciones seleccionadas.",
  cancel_succeeded: "Cancelado exitosamente",
  cancel_failed: "Ocurrió un error al cancelar",
  ends_at: "Validez",
  ends_at_default: "Válido hasta el final del período",
  number_of_user_subscriptions: "Número de suscripciones vendidas",
  number_of_simple_store_products: "Número de productos vendidos",
  number_of_active_user_subscriptions: "Suscripciones activas",
  number_of_simple_store_product_transactions: "Todos los productos vendidos",
  number_of_simple_store_product_transactions_today: "Productos vendidos hoy",
  number_of_simple_store_product_transactions_this_week:
    "Productos vendidos esta semana",
  number_of_bookings_by_amount_title: "Ingresos por reservas",
  number_of_user_subscriptions_by_amount_title: "Ingresos por suscripciones",
  number_of_simple_store_product_by_amount_title: "Ingresos de productos",
  disable_configuration: "Ocultar proceso de configuración",
  enable_configuration: "Mostrar progreso de configuración",
  quick_configuration: "Configuración rápida",
  stepper_1_title: "Su calendario ahora está en línea, ver como se ve",
  stepper_1_title_a: "Crea tu calendario en línea",
  stepper_1_description:
    "Haz clic a continuación para ver nuestra propuesta de calendario para sus clientes. Gracias a ello, puedes aceptar reservas de servicios completamente online. Lo que ves es solo una muestra de lo que Calendesk puede hacer. Establecer colores, agregar fotos, descripciones y marcadores en un creador de sitios web intuitivo.",
  stepper_1_description_a:
    "Haga clic a continuación para crear un calendario o sitio web para sus clientes. Gracias a esto, aceptarás reservas de tus servicios completamente online. Establezca colores, agregue fotos, descripciones y marcadores en un creador de sitios web intuitivo.",
  open_website_button: "Abre el sitio web",
  edit_website_button: "Editar el sitio web",
  calendar_url_link: "Tu dirección de calendario",
  stepper_2_title: "¿Quién aceptará las reservas?",
  stepper_2_description:
    "Puedes tener distintos horarios, ya sea que trabajes solo o contrates empleados o personal. La cuenta del empleado estará separada y dispondrá de su propio horario. Ya hemos creado el primer empleado para ti. Esta es tu cuenta con tu diseñador gráfico, ahora puedes personalizarla.",
  view_employees_account_button: "Ver cuentas de empleados",
  stepper_3_title: "¿Qué tipo de servicios ofrecen a sus clientes?",
  stepper_3_description:
    "Indique los servicios que desea prestar a sus clientes. Cada servicio podrá tener una configuración diferente. En la configuración del servicio puedes definir el precio, la duración y hasta puedes asignarlo a un empleado en concreto. Hemos creado un primer servicio, ahora solo tienes que adaptarlo a ti.",
  view_services_button: "Ver la lista de servicios",
  stepper_4_title: "¿Aceptará pagos en línea?",
  stepper_4_description:
    "Utilice nuestro sistema de pago en linea permitiendo así que sus clientes se beneficien de esta ventaja. Los pagos en Calendesk junto con el sistema Stripe no solo permite que sus clientes puedan realizar pagos, este avanzo sistema permite mucho mas. Se tarda solo unos minutos en configurarlo y necesario para poder aceptar los pagos en Calendesk.",
  configure_payments_button: "Configurar pagos",
  stepper_5_title: "Ayuda extra",
  stepper_5_description:
    "Necesitas más información? ¿Tiene problemas con la configuración de su sistema? Solicite una cita para una consulta gratuita con nuestro asesor o utilice el Centro de ayuda, que incluye guías en forma de artículos y videos. También estamos disponibles para usted en el chat, simplemente haga clic en el icono de chat en la esquina inferior derecha.",
  meet_us: "Consulta gratuita",
  help_center: "Centro de ayuda",
  finish: "Finalizar",
  go_to_tab: "Ir a la pestaña",
  with: "con",
  wb_page_only_with_auth:
    "La página está disponible solo para usuarios registrados",
  wb_page_only_with_auth_short: "Sólo para los conectados",
  wb_menu_button_only_with_auth:
    "El botón solo estará visible para los usuarios registrados",
  wb_remind_password_label: "Recordar enlace de contraseña",
  wb_show_remind_password_link:
    "Habilitar la capacidad de recordar la contraseña",
  wb_redirect_after_login: "Redirigir después de iniciar sesión en",
  wb_redirect_after_signup: "Redirigir a",
  wb_billing_data_checkbox_question: "Datos para una factura con IVA",
  wb_gdpr_fields: "Campos de LOPDGDD",
  wb_dummy_gdpr_field: "Acepto el Reglamento y la Política de Privacidad",
  wb_add_rgdpr_field_element: "Agregar un campo LOPDGDD",
  wb_gdpr_field_name: "El nombre interno del campo.",
  wb_dummy_gdpr_field_name: "Consentimiento LOPDGDD",
  wb_enable_discounts:
    "Habilitar la posibilidad de ingresar códigos de descuento",
  subscriptions_stripe_account_missing_description:
    "Necesita una cuenta de pago de Stripe completamente configurada para usar su suscripción.",
  optional_field: "Campo opcional",
  select_all: "Seleccionar todo",
  unselect_all: "Deseleccionar todo",
  wb_show_billing_data: "Permitir que se completen los detalles de la factura",
  edit_email: "Editar correo electrónico",
  edit_sms: "Editar SMS",
  edit_push: "Editar Push",
  booking_control_url_example:
    "https://twoja-strona.calendsk.net/bookings/5de239ca-c1e1-4040-743s-195ef52271aa",
  custom_fields_example:
    "<b>Información adicional</b> :<br> No puedo esperar para la reunión!",
  usage: "Usar",
  insufficient_permissions_error: "No tiene suficientes permisos.",
  additional_auth_required: "Su banco requiere autorización adicional",
  general_payment_error:
    "Hubo un problema con su pago. Quizás su tarjeta no admita suscripción, comuníquese con su banco o elija un método de pago diferente. Si necesita ayuda, no dude en chatear con nosotros.",
  invoice_data_update_fail:
    "Los detalles de su factura han sido rechazados. Asegúrese de que el número de IVA ingresado y el resto de los datos sean correctos e intente nuevamente.",
  wb_section_hidden_info:
    "En la pestaña 'General', puede restaurar la sección nuevamente.",
  add_to_your_website: "Inserte el código en la página",
  add_to_your_website_description:
    "Seleccione el elemento que desea insertar en su sitio web.",
  add_to_your_website_description_widget_1:
    "Inserte el código HTML a continuación en su sitio web donde desea que aparezca su calendario.",
  add_to_your_website_description_widget_2:
    "Inserte el código a continuación en su sitio web en los lugares donde desea mostrar el botón para abrir su calendario.",
  add_to_your_website_description_widget_3:
    "Inserte el código a continuación en su sitio web en los lugares donde desea mostrar el enlace para abrir su calendario.",
  widget_1_title: "Componente fijo",
  widget_1_description:
    "Tu calendario como elemento permanente del sitio web. Sus clientes verán el calendario de inmediato.",
  widget_2_title: "Una ventana con un botón.",
  widget_2_description:
    "El botón en la parte inferior de su página. Tus clientes verán el calendario con solo hacer clic en un botón.",
  widget_3_title: "Una ventana con un enlace",
  widget_3_description:
    "Agregue un enlace en forma de texto a su sitio web, que abrirá una ventana de calendario.",
  widget_dummy_1: "Reservar una cita",
  button_text: "El texto en el botón",
  link_text: "Texto del enlace",
  text_color: "El color del texto",
  background_color: "Color de fondo",
  copy_code_info: "Copia el código y pégalo en tu sitio web",
  field_only_for_services:
    "Mostrar el campo del formulario solo para los servicios seleccionados",
  user_preferences: "Ajustes",
  service_days_title:
    "Adicionalmente, limitar la posibilidad de reservar a días y horas seleccionadas (reservas limitadas por el horario del empleado y limitación adicional de horas)",
  service_days_description:
    "Puede restringir la reserva de este servicio solo a días y horas específicas de la semana. Por ejemplo, si tu servicio es solo los viernes a las 19, a pesar del horario del empleado establecido para todo el día, el cliente verá solo una hora de disponibilidad. Esta opción no es obligatoria y, si no la configura, las reservas se mostrarán de acuerdo con el horario de los empleados.",
  disconnect_stripe_account: "Desconecta tu cuenta de Stripe",
  disconnect_stripe_account_success: "Cuenta desconectada correctamente.",
  connect_current_stripe_account_question: "Ya tengo una cuenta de Stripe",
  connect_current_stripe_account_number: "Tu ID actual de Stripe",
  connect_current_stripe_account_number_error:
    "Complete su ID de Stripe o si no tiene una cuenta de Stripe, desmarque 'Ya tengo una cuenta de Stripe'.",
  stripe_connect_account_failed:
    "Hubo un error al conectar su cuenta, si el error persiste, contáctenos en el chat.",
  wb_text_hide: "Ocultar texto",
  wb_radio_add_option: "Agregar una opción de selección",
  wb_form_element_type_title: "Añade algunos campos a tu formulario",
  wb_email_subject: "El asunto del correo electrónico",
  wb_email_form_success_url:
    "Redirigir a la URL después de enviar el formulario",
  wb_email_sent: "El contenido del mensaje después de enviar el formulario.",
  form: "Forma",
  wb_section_text: "El texto de la sección",
  wb_flexible_form_info:
    "Después de enviar el formulario, se enviará a su dirección de correo electrónico definida en el panel de Calendesk (pestaña Perfil -> Su empresa). A continuación puede establecer el asunto del mensaje y seleccionar los campos que deben incluirse en el formulario.",
  wb_section_info:
    "Configure la sección seleccionada usando los elementos a continuación.",
  wb_general_tab_info:
    "Configure los ajustes generales del sitio web a continuación, como el idioma, Google Tag ID, el icono del navegador, la descripción de los robots y una imagen que se mostrará al compartir el enlace, por ejemplo, en las redes sociales. Puede copiar URL a imágenes de la galería de fotos haciendo clic en la vista previa de la imagen (en la parte inferior de la imagen verá su URL).",
  wb_pages_tab_info:
    "Al hacer clic en el nombre de una subpágina a continuación, puede ir a ella para editar sus secciones. Si desea cambiar su nombre o dirección, haga clic en el icono junto al nombre de la subpágina y seleccione 'Editar'.",
  buttons: "Botones",
  wb_form_label_info: "Etiqueta (información en el campo, por ejemplo, Nombre)",
  wb_form_description_info:
    "Descripción (información sobre el campo, por ejemplo, Ingrese su nombre)",
  click_to_edit: "Haz click para editar",
  unavailable_booking_slots: "Bloqueos de calendario",
  all_day: "Quiero establecer días completos",
  all_day_tooltip:
    "Verá los eventos creados como días completos en la barra superior del calendario y en la vista mensual.",
  block_calendar: "Bloquear el calendario",
  integrations: "Integraciones",
  google_calendar_and_meet: "Calendario de Google + Reunión de Google",
  zoom: "Zoom",
  zoom_connect_info:
    "Vincule su cuenta de Zoom con Calendesk. Gracias a la integración de Zoom con Calendesk y la configuración de ubicación adecuada para su servicio, sus reservas confirmadas contendrán un enlace de reunión único para usted y su cliente.",
  zoom_connect_info_short:
    "Líder mundial en la organización de reuniones en línea.",
  zoom_connect_button_title: "Combinar acercamiento",
  zoom_disconnect_info:
    'La conexión con Zoom está activa. Haz clic en "Desconectar" para desconectar.',
  zoom_disconnect_button_title: "Desconectar zoom",
  zoom_auth_success_title: "Correcta autorización de Zoom",
  zoom_auth_success_description:
    "Gracias, ha autorizado con éxito la cuenta de Zoom. Vaya a sus servicios para seleccionar la ubicación de la reunión usando Zoom.",
  zoom_auth_fail_description:
    "Hubo un problema al autorizar Zoom. Intentar otra vez.",
  zoom_disconnect_dialog_info:
    "¿Está seguro de que desea eliminar la integración de Zoom?",
  zoom_disconnect_success_message:
    "La integración con Zoom se ha eliminado correctamente.",
  settings_alphanumeric_sms_sender_title: "Nombre propio del remitente del SMS",
  calendesk_name_example: "Notario público. calendario",
  settings_alphanumeric_sms_sender_input_title: "Remitente de SMS",
  settings_alphanumeric_sms_sender_validation_error:
    "Formato de remitente incorrecto. Se aceptan letras, espacios y números. Mínimo una letra. Máximo 11 caracteres.",
  settings_alphanumeric_sms_sender_description:
    "Su propio nombre de remitente de SMS en mensajes a clientes. Reemplace el número de teléfono aburrido y aleatorio con su propio nombre y muestre su mejor lado. El nombre debe contener al menos una letra. Los valores aceptados son letras (sin caracteres especiales), números y espacios. Máximo 11 caracteres.",
  enable_package: "Habilitar el paquete",
  package_not_available_title:
    "Necesita un paquete adicional para usar esta función. Vaya a la tienda para habilitar el paquete.",
  subscription_update_information:
    "Si cambia su plan de suscripción o activa paquetes adicionales durante el período de facturación, el operador de pago volverá a calcular los precios y le cobrará solo la diferencia que se requiere.",
  wb_only_services_with_subscriptions:
    "Mostrar solo los servicios para los que el cliente que inició sesión tiene suscripciones activas",
  wb_use_booking_cart: "Usa el carrito de reservas",
  wb_selected_services: "Texto en el carrito de compras",
  wb_count_places_in_dates:
    "Mostrar todas las plazas disponibles por fechas (reservas de grupos)",
  number_of_sms_messages: "Número de mensajes SMS",
  api: "API",
  api_description:
    "Posibilidades ilimitadas de integración con Calendesk gracias a la conexión RESTful API. Utilice la API de Calendesk para soluciones personalizadas.",
  generate_api_key_button: "Generar una clave API",
  delete_api_key: "Eliminar la clave API",
  api_key: "Clave API",
  open_api_documentation: "Documentación de la API",
  tenant_name: "Nombre del inquilino",
  service_limits: "Limitaciones de servicios adicionales",
  service_availability_title: "Disponibilidad del servicio para los clientes.",
  choose_availability_schedule: "Seleccione un horario de disponibilidad",
  add_new_availability_schedule: "Agregar un nuevo gráfico",
  update_availability_schedule: "Edita tu horario de disponibilidad",
  add_schedules_with_plus:
    "Sin gráficos. Agregue un nuevo gráfico haciendo clic en el '+' a la derecha. '",
  schedule_name: "Nombre del parte de horas",
  display_schedule: "Editar parte de horas",
  delete_schedule: "Eliminar gráficos",
  weekdays: "Días de la semana",
  schedule_dates: "Fechas que sobrescriben los días de la semana",
  schedule_dates_info:
    "Añadir las fechas en las que la disponibilidad cambiará en relación con las horas semanales",
  add_new_date: "Agregar una fecha",
  hours_availability_info: "Horas de disponibilidad",
  select_availability_dates_info: "1. Selecciona los días disponibles",
  select_availability_hours_info: "2. Selecciona las horas disponibles",
  fill_availability_schedules:
    "Seleccione los días que estarán disponibles en la hoja de tiempo",
  plan_unavailable_error:
    "Su configuración actual de Calendesk no le permite seleccionar este plan. Si su período de prueba ha expirado, contáctenos para que podamos extenderlo.",
  value: "Valor",
  no_data: "Sin datos",
  created_by: "Creado por",
  updated_by: "Modificado por última vez por",
  updated_at: "Actualizado",
  my_booking: "Mis reservas",
  payment_user_is_required:
    "No se asigna ningún cliente. Se requiere un cliente activo para realizar un pago.",
  promo_number_of_months:
    "Número de meses durante los cuales el precio es válido:",
  wb_newsletter_success_url:
    "Boletín URL: redirección después de la suscripción",
  wb_account_activation_success_url:
    "Activación de cuenta de URL: redirección después de la activación",
  wb_show_name_surname: "Mostrar nombre y apellidos en el formulario",
  wb_show_phone_number: "Mostrar número de teléfono en el formulario",
  wb_hide_time_zone: "Ocultar selección de zona horaria",
  wb_add_code_to_website_head:
    "Copie el código a continuación y péguelo en el código de su sitio web entre las etiquetas &quot;<head> &quot;Y&quot;</head> &quot;.",
  wb_add_code_to_website_frame:
    "Copie el código a continuación y péguelo en el código de su sitio web donde desea que aparezca su calendario.",
  wb_height_desktop: "Altura de los ordenadores en píxeles",
  wb_height_mobile: "Altura en smartphones en píxeles",
  google_calendar_add_title: "Enviar reservas y bloqueos a Google Calendar",
  google_calendar_add_info:
    "Seleccionar el Calendario de Google al que debemos añadir nuevas reservas y bloqueos de calendario desde Calendesk.",
  select_calendar: "Elige un calendario",
  calendar_watch_title: "Importar eventos desde calendarios",
  google_calendar_watch_info:
    "Elija de qué calendarios de Google debemos descargar eventos. En su lugar, crearemos bloqueos en el calendario de Calendesk para desactivar su disponibilidad.",
  select_calendars: "Seleccionar calendarios",
  contact_sales: "Contactar",
  notification_contains_special_characters:
    "La notificación contiene caracteres especiales.",
  replace_invalid_sms_characters_checkbox:
    "Eliminar caracteres especiales en las notificaciones de SMS (puede afectar a una menor cantidad de SMS enviados)",
  sms_number_of_messages_info:
    "El número final de mensajes SMS enviados puede diferir después de calcular la longitud de las variables y teniendo en cuenta la opción 'Eliminar caracteres especiales en las notificaciones SMS' en la pestaña Configuración / Plataforma.",
  blackout_date_all_employees_warning:
    "Bloquea el calendario para todos los empleados.",
  no_limit: "Ilimitado",
  no_available_time_slots:
    "No hay horas disponibles. Intenta cambiar la fecha o elegir otro servicio.",
  select_availability_service_info:
    "Haga clic en el horario deseado para transferirlo al formulario de reserva.",
  select_service_error: "Seleccione un servicio para buscar términos.",
  booking_awaiting_payment_warning:
    "La reserva se eliminará si no se paga. Tiempo para pagar:",
  booking_reminder_setting_info: "Tiempo de envío antes de reservar",
  integrations_google_calendar_id_help:
    "Consulte el artículo '¿Cómo elijo el calendario correcto?'",
  booking_date_in_the_past_warning:
    "Estás intentando editar un evento que ya ha pasado. Las notificaciones por correo electrónico/SMS/push y la sincronización con calendarios externos no estarán disponibles.",
  update_draft_name_title: "Cambiar el nombre de la plantilla",
  draft_name: "Nombre de la plantilla",
  wb_duplicate_section_title:
    "Copiar una sección en una subpágina seleccionada",
  wb_duplicate_section_info:
    "Seleccione la subpágina en la que desea copiar la sección.",
  wb_duplicate_section_success_message: "Sección creada correctamente.",
  booking_customer_link: "Enlace a la reserva del cliente",
  autocomplete_hint: "Empieza a escribir para buscar",
  redirect_to_external_url: "Redirigir a URL externa",
  url_address: "Dirección URL",
  url_example: "Por ejemplo, https://twoja-strona.pl",
  open_in_new_tab: "Abrir en una pestaña nueva",
  push_all_future_events_to_calendar_checkbox:
    "Envía ahora todas las reservas futuras y los bloqueos de calendario que tienes planificados en Calendesk.",
  google_calendar_id: "ID Google Calendar ",
  set_google_calendar_id: "Establecer ID del calendario",
  google_calendar_id_info:
    "Si tiene varios calendarios de Google en una cuenta, aquí puede establecer la ID del calendario que desea usar. Si no ingresa nada, usaremos su calendario principal de Google.",
  google_calendar_example:
    "Por ejemplo, c_8gdd84889nd9nxod4@group.calendar.google.com",
  additional_options: "Opciones adicionales",
  calendar_view: "Calendario",
  require_billing_data_checkbox: "Requerir datos para una factura con IVA",
  only_company_invoice_data_type_checkbox:
    "Requerir datos completos para la factura de IVA (Número de IVA, nombre de la empresa, dirección)",
  you_can_edit_admin_password_info:
    "No puede editar la contraseña de administrador.",
  can_not_find_booking:
    "No se pudo cargar la reserva, probablemente se haya eliminado.",
  can_not_find_unavailable_booking_slot:
    "No se puede cargar el bloqueo del calendario, probablemente ha sido eliminado.",
  price_additional_info:
    "La cantidad mínima para el procesamiento de pagos es de 1 EUR (o el equivalente en dólares de 0,5 $).",
  service_types_additional_info:
    "Puede especificar una duración y un precio diferentes para cada una de las opciones de servicio.",
  service_settings: "Configuración del servicio",
  service_employee_title: "¿Quién realizará el servicio?",
  service_additional_break_info:
    "Descanso adicional antes o después de la reserva",
  service_max_people_info: "Habilitar reservas de grupo",
  stripe_payments_warning:
    "La integración de Stripe aún no está activa. Conecta Stripe para aceptar pagos por completo.",
  advance_settings_label: "Mostrar configuración avanzada",
  service_availability_employees_info:
    "Los empleados utilizan sus horarios de trabajo.",
  service_availability_service_info:
    "El servicio tiene un horario de disponibilidad para todos los empleados.",
  service_availability_employees_schedule_title: "Horario de empleados",
  service_availability_service_schedule_title: "Horario de servicio",
  service_free_option: "Gratis",
  service_max_people_short_info: "Numero de asientos",
  required_capability_info:
    "Para proteger a nuestros clientes, necesitamos verificar su cuenta para desbloquear esta función. Este es un procedimiento estándar. Hemos recibido la información y ya estamos trabajando en ello. Vuelva a intentarlo más tarde o póngase en contacto con nosotros.",
  required_permission_info:
    "Sus permisos son insuficientes para realizar esta operación. Póngase en contacto con su administrador.",
  old_employee_schedule_label: "Versión antigua del horario de trabajo",
  old_employee_schedule_label_tooltip:
    "Cambie ahora a gráficos de accesibilidad compartida para obtener mucho más.",
  employee_schedule_hint: "Por ejemplo, Jan Pracajki - horario de trabajo",
  duplicate_service: "Duplicar el servicio",
  show: "Espectáculo",
  wb_custom_code: "Guiones personalizados",
  custom_code_info:
    "Los scripts personalizados solo aparecerán en la página publicada.",
  wb_show_service_column: "Mostrar la columna con el nombre del servicio",
  wb_require_billing_data: "Requerir datos para una factura con IVA",
  private_notes: "Notas privadas",
  signup_onboarding_title_1: "¡Bienvenido a Calendesk!",
  signup_onboarding_subtitle_1:
    "Una configuración de 5 minutos ahora, le ahorrará horas de trabajo más tarde. En unos sencillos pasos, le guiaremos a través de los ajustes más importantes de Calendesk.\n",
  signup_onboarding_title_2: "Empleados",
  signup_onboarding_subtitle_2:
    "Los empleados pueden aceptar reservas en línea para servicios seleccionados y tener acceso independiente al panel de Calendesk. Cada empleado puede tener su propio horario de disponibilidad.",
  signup_onboarding_title_3: "Servicios",
  signup_onboarding_subtitle_3:
    "Los servicios aparecerán en el calendario de reservas. Agregue al menos un servicio de su oferta ahora. Posteriormente, puede agregar más servicios, agruparlos en categorías y editar los detalles.",
  signup_onboarding_title_4: "Disponibilidad del calendario",
  signup_onboarding_subtitle_4:
    "El programa de disponibilidad realiza un seguimiento de cuándo está disponible y cuándo sus clientes pueden registrarse para una cita. Puede asignarlo a empleados o servicios.",
  signup_onboarding_title_5: "Integraciones",
  signup_onboarding_subtitle_5:
    "Al integrar Calendesk con herramientas externas, puede optimizar su flujo de trabajo y aumentar su eficiencia.",
  signup_onboarding_title_6: "¡Felicidades!",
  signup_onboarding_subtitle_6:
    "Todo está listo para aceptar reservas de tus clientes.",
  signup_onboarding_card_title_1: "Acerca de ti",
  signup_onboarding_card_subtitle_1: "Añade alguna información básica.",
  signup_onboarding_card_title_2: "¿Quién usará Calendesk?",
  signup_onboarding_card_subtitle_2:
    "Agrega a las personas que contratas o con las que trabajas.",
  signup_onboarding_card_title_3: "¿Qué vas a ofrecer a tus clientes?",
  signup_onboarding_card_subtitle_3:
    "Introduce el nombre del servicio, su precio y duración. Determinar quién será responsable de la ejecución del servicio.",
  signup_onboarding_card_title_4: "Calendario de disponibilidad predeterminado",
  signup_onboarding_card_subtitle_4:
    "Asignaremos un horario por defecto a sus empleados. Más adelante, puede ajustarlo fácilmente en detalle, especificar las exclusiones, las vacaciones o los días festivos.",
  signup_onboarding_card_title_5: "Integraciones disponibles",
  signup_onboarding_card_subtitle_5:
    "A continuación encontrará una vista previa rápida de las integraciones disponibles. Puede conectarlos fácilmente en el panel de Calendesk.",
  signup_onboarding_card_title_6: "Su calendario ya está en línea",
  signup_onboarding_card_subtitle_6:
    "Hemos creado una propuesta para tu calendario de reservas. Gracias a Calendesk, puedes personalizar fácilmente la URL, los colores, las secciones, las subpáginas o cambiar el estilo del calendario.",
  signup_onboarding_services_employees_label: "¿Quién realizará el servicio?",
  signup_onboarding_domain_label:
    "Puede cambiar la dirección URL más adelante en el creador del sitio web.",
  signup_onboarding_employee_is_public_info:
    "Una cuenta de empleado puede aceptar reservas de los clientes, pero también puede ser utilizada por su empleado sólo para iniciar sesión para ver el calendario, por ejemplo. Esta opción se puede cambiar fácilmente más adelante.\n",
  online_bookings: "Reservas en línea",
  integrations_category_calendars: "Calendarios",
  integrations_category_video: "Videollamadas",
  integrations_category_payments: "Pagos",
  integrations_category_accounting: "Facturación",
  integrations_category_automation: "Automatización",
  integrations_category_analytics: "Análisis",
  integrations_category_www: "Sitio web",
  coming_soon_info:
    "Opción disponible en breve. Le informaremos en cuanto hayamos terminado de trabajar en él.",
  go_to_services_button: "Ir a servicios",
  start_integration_label: "Iniciar integración",
  integrations_for_employee_tab: "Integraciones de empleados",
  integrations_for_global_tab: "Integraciones globales",
  zapier: "Zapier",
  zapier_description:
    "Zapier le permite intercambiar información entre aplicaciones aparentemente no relacionadas, de modo que puede transferir datos de Calendesk a otras aplicaciones y realizar las acciones adecuadas en ellas.",
  zapier_description_short:
    "¡Transfiere datos de Calendesk a más de 4000 aplicaciones!",
  google_tag_manager: "Administrador de etiquetas de Google",
  google_tag_manager_description:
    "Administre todas las integraciones en su sitio web en un solo lugar. Google Tag Manager es una herramienta avanzada que le permitirá agregar cualquier pieza de código a su calendario o sitio web en Calendesk.",
  google_tag_manager_description_short:
    "Administre todas las integraciones en su sitio web en un solo lugar.",
  calendesk_widget: "Complementos de Calendesk",
  calendesk_widget_description:
    "Coloque el sistema de reservas de Calendesk en cualquier sitio web. Decida si desea usar el sistema como un elemento de página, una ventana emergente o tal vez un botón visible permanente en la parte inferior de la página. Vaya al asistente y copie el código del complemento generado. Póngalo en su sitio web y permita que los clientes programen una cita directamente en su sitio web.",
  calendesk_widget_description_short:
    "Coloque el sistema de reservas de Calendesk en cualquier sitio web.",
  google_analytics: "Google Analytics",
  google_analytics_description:
    "Google Analytics proporciona las herramientas gratuitas necesarias para analizar los datos de su negocio y sitio web en un solo lugar, para que pueda tomar decisiones más inteligentes.",
  microsoft_teams: "Teams",
  microsoft_skype: "Skype",
  microsoft_outlook: "Outlook",
  work_schedule: "Horario de trabajo",
  category_default_name: "Consultas",
  root_email_info:
    "Cambiará el correo electrónico principal después de la configuración.",
  email_must_be_unique:
    "Las direcciones de correo electrónico son opcionales y no se pueden duplicar.",
  value_must_be_unique: "El valor introducido no se puede repetir.",
  billing_data: "Datos de facturación",
  global_error_title: "Lo sentimos, hubo un problema al cargar Calendesk.",
  global_error_subtitle: "Este problema suele darse por dos motivos:",
  global_error_description_1:
    "1. <b>Conexión a Internet</b> . Intente verificar su conexión a Internet y actualice la página.",
  global_error_description_2:
    "2. <b>Actualización del sistema Calendesk</b> . A veces hacemos actualizaciones del sistema para que funcione mejor. Intente actualizar la página en unos minutos.",
  main_page_button_title: "Página de inicio",
  reload: "Actualizar",
  wb_show_service_description: "Mostrar detalles del servicio",
  wb_hide_booking_time_to: "Ocultar la hora de finalización de la reserva\n",
  wb_redirect_logo: "Redirección con logotipo",
  collapse_all: "Colapsar todos",
  expand_all: "Expandir todo",
  service_status: "Estado del servicio",
  availability: "Disponibilidad",
  add_availability_schedule: "Agregar un horario de disponibilidad",
  availability_list_info:
    "Use horarios de disponibilidad para mostrar franjas horarias de reserva para sus clientes. Los horarios se pueden asignar a empleados, servicios o ambos. Puede compartir los horarios de disponibilidad entre empleados o servicios. Todos los empleados pueden acceder y editar/eliminar horarios de trabajo si se les han asignado o han sido creado por ellos.",
  assigned_to_employees: "Asignado a los empleados",
  assigned_to_services: "Asignado a servicios",
  assign_to_employees: "Asignar a los empleados",
  assign_to_services: "Asignar a los servicios",
  activate_work_schedule: "Asignar a mí",
  section_admin_availability_list: "Panel / Disponibilidad",
  test_availability: "Disponibilidad de prueba",
  employee_assigned_to_services_title: "Servicios realizados por el empleado",
  employee_assigned_to_services_info:
    "La reserva de un servicio requiere la asignación de un empleado que lo realizará. A continuación puede seleccionar los servicios que deben asignarse a este empleado.",
  wb_column_width: "Ancho de columna en porcentaje, por ejemplo, 50",
  max_booking_time: "Reservar ya tu servicio",
  max_user_bookings: "El número máximo de reservas para un cliente",
  max_user_bookings_checkbox: "Limite el número de reservas para los clientes",
  create_user_subscription_button_title: "Asignar suscripción",
  create_user_subscription_info_1:
    "Puede crear cualquier tipo de suscripción sin involucrar a los clientes en su compra. Los abonos renovables con límites de reserva, renovarán sus límites según su configuración. Estas suscripciones permanecerán activas hasta que se cancelen manualmente. En el caso de las suscripciones puntuales (paquetes), éstas permanecerán activas hasta que el cliente haya agotado sus recursos o se cancelen manualmente. El sistema no lleva un registro de los pagos de las suscripciones/paquetes manuales y no cancelará dichas suscripciones en caso de impago. Puede gestionar los pagos de las suscripciones manuales en Clientes/Suscripciones. También puede crear su primer pago de suscripción a continuación.\n\n",
  create_user_subscription_info_2:
    "Seleccione la suscripción que desea asignar al cliente. Puede especificar, la fecha de creación (venta) y la fecha de finalización de la suscripción. La fecha de creación de la suscripción debe ser una fecha pasada. Opcionalmente, añada la información del pago si éste se realizó fuera del sistema Calendesk.",
  create_subscription_payment: "Quiero crear un pago",
  create_payment: "Crear pago",
  user_subscription_id: "ID de suscripción del cliente",
  booking_id: "ID de reserva",
  create_payment_for_user_subscription:
    "Crear un pago para una suscripción de cliente",
  create_payment_invoice_info:
    "La factura del IVA se emitirá automáticamente si tienes correctamente configurada la integración con el sistema de facturación y has seleccionado la opción de emitir facturas del IVA al recibir el pago.",
  notification_usage_not_available_info:
    'No tienes suficientes notificaciones para enviar mensajes. Ve a la pestaña "Notificaciones" para recargar tu cuenta.',
  buy_notifications: "Comprar notificaciones",
  notifications_sent: "Tu mensaje fue enviado.",
  employee_names: "Nombre y apellidos del empleado",
  pay_with_subscription_error_message:
    "No puedes pagar con la suscripción seleccionada - sus límites están agotados, está inactiva o su fecha de creación es posterior a la fecha de la reserva.",
  subscription_created_at: "Fecha de inicio de la suscripción",
  subscription_ends_at: "Fecha de finalización de la suscripción",
  wants_create_subscription_end_date_checkbox:
    "Quiero establecer una fecha de finalización de la suscripción",
  manage: "Administrar",
  status_and_payment: "Estado del pago",
  duplicate_booking: "Duplicar la reserva",
  edit_booking: "Editar reserva",
  edit_unavailable_booking_slot: "Edite la fecha de bloqueo",
  delete_unavailable_booking_slot: "Eliminar la fecha de bloqueo",
  disable_indexing_by_robots:
    "No permitir a los robots de motores de búsqueda indexar la página de compra de esta suscripción",
  show_employees_working_hours:
    "Mostrar los horarios de disponibilidad de los empleados en el calendario",
  integrations_category_employees: "Calendarios y videoconferencias",
  integrations_google_tools_title: "Servicios de Google",
  integrations_microsoft_tools_title: "Servicios de Microsoft",
  outlook_calendar_connect_button_title: "Comience a integrarse con Microsoft",
  google_calendar_connect_button_title: "Comience a integrarse con Google",
  google_calendar_description:
    "Accede a tus reservas en tu Google Calendar. Reciba información sobre sus nuevas reservas o actualizaciones directamente en su calendario personal conectando Calendesk a su cuenta de Google. Permita que Calendesk verifique los eventos de su calendario para realizar un seguimiento de su disponibilidad.",
  outlook_calendar_description:
    "Accede a tus reservas en tu Outlook Calendar. Reciba información sobre sus nuevas reservas o actualizaciones directamente en su calendario personal conectando Calendesk a su cuenta de Microsoft. Permita que Calendesk verifique los eventos de su calendario para realizar un seguimiento de su disponibilidad.",
  microsoft_teams_description:
    "Configure su ubicación de Calendesk en Teams para que con cada reserva, Calendesk cree un enlace de reunión único. El enlace se le enviará a usted y a su cliente tan pronto como se realice la reserva.",
  microsoft_skype_description:
    "Configure su ubicación de Calendesk en Skype para que con cada reserva, Calendesk cree un enlace de reunión único. El enlace se le enviará a usted y a su cliente tan pronto como se realice la reserva.",
  whatsapp: "WhatsApp",
  whatsapp_description:
    "Vincula tu número de WhatsApp a Calendesk. Gracias a la integración de WhatsApp con Calendesk y la configuración de ubicación adecuada para su servicio, sus reservas confirmadas contendrán un enlace de reunión único para usted y su cliente.",
  whatsapp_description_short:
    "Llamadas y mensajes rápidos, simples y seguros disponibles en teléfonos de todo el mundo.",
  whatsapp_connect_button_title: "Conecta con WhatsApp",
  calendar_public_label:
    "Mostrar descripciones de eventos privados después de descargarlos",
  calendar_public_tooltip:
    "Calendesk puede mostrar títulos de eventos privados de sus calendarios conectados. Las descripciones serán visibles para todos sus colegas que tengan acceso a sus reservas y fechas restringidas. Cambiar esta opción solo afecta los futuros eventos importados.",
  outlook_calendar_add_title: "Enviar reservas y bloqueos a Outlook Calendar",
  outlook_calendar_add_info:
    "Seleccionar el Calendario de Outlook al que debemos añadir nuevas reservas y bloqueos de calendario desde Calendesk.",
  outlook_calendar_watch_info:
    "Elija de qué calendarios de Outlook debemos descargar eventos. En su lugar, crearemos bloqueos en el calendario de Calendesk para desactivar su disponibilidad.",
  outlook_calendar_auth_success_title: "Correcta autorización de Microsoft",
  outlook_calendar_disconnect_dialog_title: "Desconectar Outlook",
  outlook_calendar_disconnect_dialog_info:
    "¿Seguro que quieres desconectar tu Outlook Calendar? Sus futuras visitas y bloqueos de Calendesk ya no se enviarán a su Calendario de Microsoft.",
  outlook_calendar_disconnect_success_message:
    "El calendario se ha desconectado correctamente.",
  microsoft_supported_tools:
    "Integraciones admitidas para su cuenta de Microsoft",
  whatsapp_add_title: "Tu número de WhatsApp",
  whatsapp_add_info:
    "Ingrese el número de WhatsApp que desea utilizar para comunicarse con sus clientes. Este número solo se asignará a su cuenta de Calendesk.",
  whatsapp_disconnect_dialog_title: "Desconectar WhatsApp",
  whatsapp_calendar_disconnect_dialog_info:
    "¿Seguro que quieres desconectar la integración de WhatsApp? Sus futuras reservas de Calendesk ya no estarán vinculadas a este número de WhatsApp.",
  whatsapp_calendar_disconnect_success_message:
    "La integración de WhatsApp se ha desconectado correctamente.",
  recurrence_event: "Periódica",
  google_services_description_short:
    "Acceso a reservas en tu calendario de Google. Use Google Meet para videollamadas con clientes.",
  microsoft_services_description_short:
    "Envíe reservas a su calendario de Outlook. Conéctese con clientes usando Microsoft Teams o Skype.",
  notifications_running_out_of_notifications:
    "Sus notificaciones por correo electrónico/SMS se están agotando. Recarga para mantener a tus clientes informados sobre las reservas.",
  choose_email_notifications_package:
    "Seleccione un paquete de notificación por e-mail",
  choose_sms_notifications_package:
    "Seleccione un paquete de notificación por SMS",
  notifications_packages_info:
    "Los paquetes de notificación no caducan. Puede usarlos siempre que use Calendesk. Las notificaciones de paquetes se usan cuando se te acaban las que recibes cada mes en tu plan.",
  never_expires: "Válido para siempre",
  email_package: "Paquete de e-mail",
  sms_package: "Paquete de SMS",
  email_packages: "Paquetes de e-mail",
  sms_packages: "Paquetes de SMS",
  extra_notification: "notificaciones adicionales",
  go_to_notifications: "Ir a notificaciones",
  days: "Días",
  hours: "Horas",
  hour: "Hora",
  minutes_full: "Minutos",
  seconds: "Segundos",
  black_friday_title: "Black Friday 2022!",
  black_friday_subtitle: "¡No te olvides de tus clientes!",
  black_friday_ends_info: "Hasta el final de la promoción",
  black_friday_info_1:
    "¡Hemos preparado una promoción especial para notificaciones por e-mail y SMS! Reciba notificaciones adicionales al comprar paquetes por e-mail o SMS.",
  black_friday_info_2:
    "¡Utilica la posibilidad de enviar notificaciones por e-mail y SMS a tus clientes y prepara algo extra para ellos!",
  show_canceled_bookings: "Mostrar reservas canceladas en el calendario",
  upselling_title: "Venta adicional",
  upselling_info:
    "Aumenta los ingresos y la satisfacción del cliente ofreciendo productos o servicios adicionales. Puedes elegir los productos que se ofrecerán además de la reserva del servicio. Esta es una excelente manera de hacer upselling, es decir, vender productos adicionales cuando el cliente realiza una reserva de servicio.",
  upselling_info_2:
    'La función "Productos" (venta adicional) puede traer muchos beneficios a tu negocio durante el proceso de reserva. Al ofrecer productos o servicios adicionales, puedes aumentar tanto los ingresos como la satisfacción del cliente. Puedes asignar productos seleccionados a servicios específicos editándolos en la pestaña "Oferta/Servicios".',
  simple_store_products: "Productos",
  add_simple_store_products_with_plus:
    'No hay productos. Añada un producto haciendo clic en el "+" de la derecha.',
  product_has_transactions_delete_error:
    "No puede eliminar un producto que ya se vendió y tiene transacciones de pago.",
  additional_information: "Información adicional",
  simple_product_name_example: "Ejemplo de nombre de producto",
  simple_product_description_example: "Recogerá el producto durante su visita.",
  simple_store_product_description_title:
    "Información adicional del producto después de la compra",
  simple_store_product_description_info:
    "Información adicional que se proporcionará después de comprar el producto en una notificación por correo electrónico.",
  pay_for_products: "Paga por los productos",
  add_transaction: "Añadir transacción",
  additional_products: "Productos adicionales",
  christmas_wishes_title: "¡Felices Fiestas Navideñas! 🎅🌟",
  christmas_wishes_subtitle_1:
    "Las Fiestas Navideñas y el Año Nuevo son un tiempo especial, por eso te deseamos no solo alegría y felicidad, sino también todo lo mejor y la realización de tus sueños. Esperamos que pases este tiempo rodeado de tus seres queridos y disfrutes cada momento. ¡Que el Año Nuevo te traiga mucha alegría y satisfacción por tus logros!",
  christmas_wishes_subtitle_2: "Equipo Calendesk 🤗",
  user_requested_employee_title: "El cliente ha seleccionado un empleado.",
  subscription_renews_info: "Tu suscripción se renovará",
  product_active: "El producto está activo",
  simple_store_product_additional_information_title:
    "Información adicional del producto antes de la compra",
  simple_store_product_additional_information_description:
    "Se mostrará información adicional durante la compra del producto cuando el producto se compre de forma independiente (fuera del sistema de reservas).",
  upselling_description_title: "Texto adicional",
  upselling_description_subtitle:
    "Texto adicional que mostraremos al reservar el servicio. Puede usarlo, por ejemplo, para promocionar productos adicionales.",
  wb_init_calendar_title_example:
    "Selecciona un servicio para ir al calendario de reservas 👇",
  wb_init_calendar_title: "Título al seleccionar un servicio",
  wb_initial_service_selection:
    "Mostrar página de selección de servicios iniciales",
  add_to_google_calendar_button: "Añadir al Calendario de Google",
  add_to_google_ical_outlook_button: "Añadir a iCal/Outlook",
  add_to_calendar_variable_title: 'Botones "Añadir a calendario"',
  manage_booking_button_title: "Administra tu reserva",
  manage_bookings_button_title: "Ir a reservas",
  manage_booking_button_variable_title: 'Botón "Administra tu reserva"',
  manage_bookings_button_variable_title: 'Botón "Ir a reservas"',
  wb_language_not_supported_info:
    "Desafortunadamente, el creador de sitios web de Calendesk no admite totalmente el idioma que ha elegido. Pero no se preocupe, sus clientes aún podrán ver fragmentos traducidos que usted no puede cambiar.",
  group_booking_title: "Reserva de grupo",
  edit_group_booking_title: "Edición de reserva de grupo",
  edit_single_instance_group_booking_title:
    "Edición de una sola reserva de un grupo",
  group_booking_description: "Un empleado y muchos clientes durante la reunión",
  edit_booking_title: "Edición de una reserva",
  single_booking_description: "Un empleado y un cliente durante la reunión",
  blackout_date_title: "Bloqueo de calendario",
  edit_unavailable_booking_slot_title: "Edición de un bloqueo de calendario",
  blackout_date_description:
    "Bloquea la capacidad de los clientes para hacer reservas",
  select_service_from_list_title: "Elige un servicio",
  select_services_title: "Seleccione servicios",
  availability_window_button_title: "Ver la disponibilidad de los empleados",
  booking_form_service_question:
    "¿Para qué servicio quieres reservar una cita?",
  booking_form_employee_question: "¿Quién debe realizar la reunión?",
  booking_form_user_question: "¿Con quién será la reunión?",
  booking_form_date_question: "¿Cuándo debe tener lugar la reunión?",
  booking_location_question: "¿Dónde debe tener lugar la reunión?",
  booking_form_additional_question: "Ajustes adicionales",
  blackout_date_form_date_question: "¿Cuándo quieres bloquear el calendario?",
  blackout_date_form_employee_question:
    "¿A quién quieres bloquearle el calendario?",
  available_hours_title:
    "Las próximas fechas disponibles del día seleccionado para los empleados (@{date}):",
  update_recurring_event_title: "Edición de un evento recurrente",
  update_recurring_event_question:
    "Este evento es recurrente. ¿Qué quieres editar?",
  event_updated_no_notifications_label: "No informes sobre el cambio de visita",
  type: "Tipo",
  event_type: "Tipo de evento",
  event_type_booking: "Reserva",
  event_type_group_booking: "Reserva de grupo",
  event_type_unavailable_booking_slot: "Bloqueo de calendario",
  calendesk: "Calendesk",
  blackout_date_default_value: "Bloqueo de calendario Calendesk",
  booking_recurrence_description:
    "cada tercer día, comenzando desde el 10.06.2023, 10 veces",
  manage_booking_event: "Reserva",
  manage_unavailable_booking_slot_event: "Bloqueo de calendario",
  wants_set_end_time_label:
    "Quiero establecer el final de la reunión manualmente",
  wants_set_end_time_info:
    "Elija la opción de establecer manualmente la hora de finalización de la reunión en lugar de la duración del servicio estándar.",
  new_booking_title: "Nueva reserva",
  new_unavailable_booking_slot_title: "Nuevo bloqueo de calendario",
  show_more_employees: "Mostrar siguiente (@{number})",
  select_employee_label: "Elija un empleado",
  select_employees_label: "Seleccionar empleados",
  select_customer_label: "Elija un cliente",
  select_customers_label: "Elija clientes",
  booking_from_group: "Reserva de un grupo",
  bookings_in_group: "Reservas en un grupo",
  event_preview_type_booking: "Vista previa de la reserva",
  event_preview_type_unavailable_booking_slot:
    "Vista previa del bloqueo de calendario",
  event_preview_type_group_booking: "Vista previa de la reserva de grupo",
  event_history_title: "Historial de cambios",
  events: "Eventos",
  download_bookings_csv_title: "Descargar CSV (Reservas)",
  download_unavailable_booking_slots_csv_title:
    "Descargar CSV (Bloqueos de calendario)",
  employee_is_public_filter_title: "Reservas de clientes",
  employee_is_public_filter_visible: "Acepta reservas",
  employee_is_public_filter_hidden: "No acepta reservas",
  wb_calendar_min_date: "Muestra disponibilidad desde el día",
  wb_calendar_max_date: "Muestra disponibilidad hasta el día",
  delete_user_subscriptions: "Eliminar suscripciones de clientes",
  cancel_user_subscriptions: "Cancelar suscripciones de clientes",
  payment_date: "Fecha de pago",
  active_subscriptions: "Suscripciones activas",
  inactive_subscriptions: "Suscripciones inactivas",
  newsletter_new_record_info:
    "Enviaremos un mensaje a la dirección de correo electrónico proporcionada, a través del cual el usuario podrá confirmarlo.",
  google_calendar_permissions_error:
    "Calendesk no tiene acceso completo a tu lista de calendarios. Es posible que hayas desmarcado los permisos requeridos durante la integración con Google Calendar. Intenta desconectar y volver a conectar la integración. Si no quieres compartir tu lista de calendarios con nosotros, introduce manualmente el número de ID único del calendario.",
  description_too_long: "La descripción introducida es demasiado larga",
  identity_number_label: "Número interno",
  sales: "Ventas",
  offer: "Oferta",
  tools: "Herramientas",
  activity: "Actividad",
  billing: "Facturación",
  notification_activity_info:
    "Aquí encontrarás el historial de mensajes de correo electrónico, SMS o push enviados manualmente a tus clientes.",
  notification_activity_info_2:
    "Actualmente estamos trabajando en una función que pronto te permitirá revisar los mensajes enviados automáticamente.",
  root_account_title: "Cuenta principal",
  blackout_date_move_to_another_resource_error:
    "No puedes cambiar el bloqueo del calendario asignándolo a otro empleado. Intenta eliminar el bloqueo y agregarlo de nuevo.",
  cancel_group_bookings_title: "Cancela todo el grupo",
  delete_group_bookings_title: "Elimina todo el grupo",
  duplicate_group_bookings_title: "Duplica todo el grupo",
  logs_title: "Registros de actividad",
  logs_type_title: "Tipo de cambio",
  logs_diff_before_title: "Cambio antes",
  logs_diff_after_title: "Cambio después",
  logs_diff_name: "Nombre",
  logs_diff_surname: "Apellido",
  logs_diff_email: "E-mail",
  logs_diff_status: "Estado",
  logs_diff_date_of_birth: "Fecha de nacimiento",
  logs_diff_gender: "Género",
  logs_diff_identity_number: "Número interno",
  logs_diff_firebase_token: "Token interno (PUSH)",
  logs_diff_draft_uuid: "ID del borrador",
  logs_diff_stripe_id: "ID de Stripe",
  logs_diff_fakturownia_id: "ID de Bitfactura",
  logs_diff_pm_type: "Tipo de tarjeta",
  logs_diff_pm_last_four: "Los últimos cuatro dígitos de la tarjeta",
  logs_diff_payment_method_id: "ID del método de pago",
  logs_diff_gdpr_fields: "Campos de LOPDGDD",
  logs_diff_email_verified_at: "Verificacion de email",
  logs_diff_date_of_names_day: "Fecha del día del nombre",
  logs_diff_card_number: "Número de tarjeta",
  logs_diff_description: "Descripción",
  logs_diff_start_date: "Fecha de inicio",
  logs_diff_end_date: "Fecha de fin",
  logs_diff_start_time: "Hora de inicio",
  logs_diff_end_time: "Hora de fin",
  logs_diff_control: "Número de control",
  logs_diff_custom_fields: "Campos personalizados",
  logs_diff_google_calendar_event_id: "Id del evento del calendario de Google",
  logs_diff_google_meet_url: "Enlace de reunión de Google",
  logs_diff_paid: "Pago",
  logs_diff_skip_create_notifications: "Omitir las notificaciones de creación",
  logs_diff_skip_update_notifications:
    "Omitir las notificaciones de actualización",
  logs_diff_skip_delete_notifications:
    "Omitir las notificaciones de eliminación",
  logs_diff_group_id: "ID del grupo",
  logs_diff_service_location_id: "ID de la ubicación",
  logs_diff_paid_with_user_subscription_id:
    "Pagado con la ID de suscripción del usuario",
  logs_diff_recurrence_params: "Parámetros de recurrencia",
  logs_diff_payment_method: "Método de pago",
  logs_diff_service_id: "ID del servicio",
  logs_diff_service_type_id: "ID de la opción de servicio",
  logs_diff_employee_id: "ID del empleado",
  logs_diff_user_id: "ID del cliente",
  logs_diff_multi_slot_group_id: "ID de reserva de grupo",
  logs_diff_zoom_meeting_id: "ID de la reunión de Zoom",
  logs_diff_zoom_start_url: "URL de inicio de Zoom",
  logs_diff_zoom_join_url: "URL de unión a Zoom",
  logs_diff_created_by: "Creado por",
  logs_diff_updated_by: "Actualizado por",
  logs_diff_customer_time_zone: "Zona horaria del cliente",
  logs_diff_zoom_occurrence_id: "ID de ocurrencia única de Zoom",
  logs_diff_customer_time_notation: "Notación de tiempo del cliente",
  logs_diff_outlook_calendar_event_id:
    "Id del evento del calendario de Outlook",
  logs_diff_booking_payment_transaction_id:
    "ID de transacción de pago de la reserva",
  logs_diff_teams_url: "URL de Teams",
  logs_diff_skype_url: "URL de Skype",
  logs_diff_employee_whatsapp_url: "URL de WhatsApp del empleado",
  logs_diff_customer_whatsapp_url: "URL de WhatsApp del cliente",
  logs_diff_image_id: "ID de imagen",
  logs_diff_data: "Datos",
  logs_diff_success_url: "URL de éxito",
  logs_diff_recurring_interval: "Repetir temporalmente",
  logs_diff_service_limit: "Límite de servicio",
  logs_diff_tax: "Impuesto",
  logs_diff_price: "Precio",
  logs_diff_quantity: "Cantidad",
  logs_diff_additional_information: "Información adicional",
  logs_diff_rules: "Reglas",
  logs_diff_canceled_at: "Fecha de cancelación",
  logs_diff_wants_invoice: "Factura",
  logs_diff_cancel_url: "URL de cancelación/error",
  logs_diff_require_billing_data: "Requerir datos para una factura con IVA",
  logs_diff_disable_robot_indexing:
    "No permita que los robots de los motores de búsqueda indexen esta suscripción",
  logs_diff_only_company_invoice_data_type:
    "Requerir datos completos para la factura de IVA (Número de IVA, nombre de la empresa, dirección)",
  logs_diff_stripe_subscription_id: "ID de suscripción de Stripe",
  logs_type_created: "Creado",
  logs_type_updated: "Cambio",
  logs_type_deleted: "Eliminado",
  logs_subject_type: "Tipo de objeto",
  logs_subject_type_user: "Usuario",
  logs_subject_type_booking: "Reserva",
  logs_subject_type_subscription: "Suscripción",
  logs_subject_type_employee: "Empleado",
  logs_subject_type_unavailablebookingslot: "Bloqueo de calendario",
  logs_subject_type_service: "Servicio",
  logs_subject_type_servicetype: "Variante",
  logs_subject_type_servicelocation: "Vinculación de ubicación con servicio",
  logs_subject_type_serviceemployee: "Vinculación de empleado con servicio",
  logs_subject_type_servicesimplestoreproduct:
    "Vinculación de producto con servicio",
  logs_subject_type_serviceresource: "Vinculación de recurso con servicio",
  logs_subject_type_bookingtag: "Vinculación de etiqueta con reserva",
  logs_subject_type_servicesubscription:
    "Vinculación de suscripción con servicio",
  logs_subject_type_bookingsimplestoreproduct:
    "Vinculación de producto con reserva",
  logs_subject_type_usergroup: "Vinculación de grupo con usuario",
  logs_subject_type_category: "Categoría",
  logs_subject_type_userpreferences: "Preferencias del usuario",
  logs_subject_type_employeeworkinghour: "Horas de trabajo del empleado",
  logs_subject_type_group: "Grupo",
  logs_subject_type_permission: "Permiso",
  logs_subject_type_role: "Rol",
  logs_subject_type_subscriptionpaymenttransaction: "Pago de suscripción",
  logs_subject_type_simplestoreproducttransaction: "Pago del producto",
  logs_subject_type_bookingpaymenttransaction: "Pago de la reserva",
  logs_subject_type_usersubscription: "Suscripción del cliente",
  logs_subject_type_apikey: "Clave de API",
  logs_subject_type_availabilityschedule: "Horas de disponibilidad",
  logs_subject_type_tag: "Etiqueta",
  logs_subject_type_employeewatchedgooglecalendar: "Calendario de Google",
  logs_subject_type_employeewatchedoutlookcalendar: "Calendario de Outlook",
  logs_subject_type_resource: "Recursos",
  logs_subject_type_product: "Producto",
  logs_subject_type_settings: "Ajustes",
  logs_subject_type_simplestoreproduct: "Producto",
  logs_subject_type_address: "Dirección",
  logs_subject_type_phone: "Teléfono",
  logs_subject_type_templatesettings: "Configuraciones de plantilla",
  logs_subject_type_location: "Ubicación",
  logs_changer_name: "Cambista",
  employee_not_available_in_this_service_error:
    "El empleado no está asignado a este servicio.",
  logs_diff_booking_id: "ID de reserva",
  logs_diff_transaction_id: "Número de transacción/comentario",
  logs_diff_provider_tag: "Método",
  logs_diff_paid_at: "Pagado",
  logs_diff_promo_code: "Código de descuento",
  logs_diff_currency: "Moneda",
  logs_diff_email_notification_booking_created:
    "Confirmación de reserva - e-mail",
  logs_diff_email_notification_booking_updated: "Cambio de reserva - e-mail",
  logs_diff_email_notification_booking_deleted:
    "Cancelación de reserva - e-mail",
  logs_diff_email_notification_booking_reminder:
    "Recordatorio de reserva - e-mail",
  logs_diff_sms_notification_booking_created: "Confirmación de reserva - SMS",
  logs_diff_sms_notification_booking_updated: "Cambio de reserva - SMS",
  logs_diff_sms_notification_booking_deleted: "Cancelación de reserva - SMS",
  logs_diff_sms_notification_booking_reminder: "Recordatorio de reserva - SMS",
  logs_diff_model_id: "Relacionado con ID",
  logs_diff_e164: "Teléfono",
  logs_diff_street: "Calle",
  logs_diff_postal_code: "Código postal",
  logs_diff_city: "Ciudad",
  logs_diff_country_iso_code: "País",
  logs_diff_vat_iso_prefix: "Prefijo de NIF",
  logs_diff_tax_number: "NIF",
  logs_diff_category_id: "ID de la categoría",
  logs_diff_duration: "Duración",
  logs_diff_booking_time_before: "Reserva del servicio a más tardar",
  logs_diff_cancel_time_before: "Cancelación del servicio a más tardar",
  logs_diff_change_time_before: "Reprogramación del servicio a más tardar",
  logs_diff_max_people: "Numero de asientos",
  logs_diff_slots_every:
    "Muestre a los clientes la oportunidad de reservar cada",
  logs_diff_allow_online_payment:
    "Habilitar los pagos en línea para este servicio",
  logs_diff_awaiting_booking_payment_time: "Tiempo para pagar la reserva",
  logs_diff_payment_required: "Pago por adelantado",
  logs_diff_order_position: "Posición",
  logs_diff_max_booking_time: "Reserva del servicio lo más temprano posible",
  logs_diff_upselling_description: "Texto adicional",
  logs_diff_type: "Tipo",
  logs_diff_interval_count: "Repetir cada",
  logs_diff_color: "Color",
  logs_diff_is_public: "Acepta reservas",
  logs_diff_title: "Título",
  logs_diff_facebook: "Enlace al perfil en Facebook",
  logs_diff_instagram: "Enlace al perfil de Instagram",
  logs_diff_youtube: "Enlace al perfil en Youtube",
  logs_diff_twitter: "Enlace al perfil de Twitter",
  logs_diff_availability_schedule_id: "ID del horario de disponibilidad",
  logs_diff_fakturownia_invoice_id: "Bitfactura ID",
  logs_diff_invoice_number: "Número de factura",
  logs_diff_user_subscription_id: "ID de suscripción del cliente",
  logs_diff_uuid: "ID",
  logs_diff_id: "ID",
  logs_diff_tag_id: "ID de etiqueta",
  logs_diff_timezone: "Zona horaria",
  logs_diff_google_calendar_enabled: "Calendario de Google habilitado",
  logs_diff_zoom_user_email: "E-mail del usuario de Zoom",
  logs_diff_google_calendar_id: "ID de calendario de Google",
  logs_diff_teams_business_enabled: "Teams para negocios habilitados",
  logs_diff_skype_business_enabled: "Skype para negocios habilitado",
  logs_diff_skype_consumer_enabled: "Skype para consumidores habilitado",
  logs_diff_outlook_enabled: "Outlook habilitado",
  logs_diff_outlook_calendar_id: "ID de calendario de Outlook",
  logs_diff_is_google_calendar_public:
    "Las descripciones del calendario de Google son visibles",
  logs_diff_is_outlook_public:
    "Las descripciones del calendario de Outlook son visibles",
  logs_diff_whatsapp_number: "Número de WhatsApp",
  logs_diff_break_time_before: "Tiempo de descanso antes",
  logs_diff_break_time_after: "Tiempo de descanso después",
  logs_diff_only_subscriptions: "Solo suscripciones",
  logs_diff_max_user_bookings: "Máximo de reservas de usuario",
  logs_diff_simple_store_product_id: "ID de producto",
  logs_diff_resource_id: "Id de recurso",
  logs_diff_subject: "Título",
  logs_diff_content: "Contenido",
  logs_diff_type_id: "ID de tipo",
  subject_id: "Número de ID",
  logs_diff_subscription_id: "ID de suscripción",
  logs_subject_type_notificationtemplate: "Plantillas de notificaciones",
  no_integration_employee_warning:
    "Las integraciones del empleado seleccionado no permiten realizar una reunión en la ubicación elegida. El enlace de la reunión no se generará. Cambia la ubicación o elige a otro empleado.",
  single_booking_in_group_edit_warning:
    "Estás editando una reserva individual, que está asignada a un grupo que tiene configuradas reuniones recurrentes. En este caso, no puedes cambiar el servicio, la hora y el empleado. Si la reserva está desactualizada, cancela y vuelve a añadirla con los ajustes adecuados. Si deseas cambiar los ajustes para todo el grupo, ve a la edición grupal.",
  group_booking_update_unavailable_in_calendar_view:
    "No es posible editar una reserva de grupo arrastrando y soltando. Para hacer ediciones, haz clic en la reserva.",
  event_deleting_title: "Borrando eventos",
  event_deleting_question:
    "¿Estás seguro de que quieres borrar el/los evento/s seleccionado/s?",
  do_not_group_booking_checkbox_label: "No agrupes las reservas de alojamiento",
  employee_limit_dialog_info:
    "Tu plan actual no permite agregar un nuevo empleado. Para crear una nueva cuenta, por favor contáctanos a través de chat o correo electrónico en",
  tenant_account_frozen_error:
    "Tu cuenta ha estado inactiva durante mucho tiempo. Estamos restaurando tus datos. Por favor, intenta de nuevo en unos minutos.",
  client_website_bookings_tab: "Pestaña de Reservas",
  client_website_user_subscriptions_tab: "Pestaña de Suscripciones",
  client_website_payments_tab: "Pestaña de Pagos",
  client_website_my_account_tab: "Pestaña de Mi Cuenta",
  invoice_warning_client_data:
    "La factura del IVA no se emitirá debido a la falta de datos completos de la factura del cliente.",
  invoice_warning_fakturownia_disabled:
    "La factura del IVA no se emitirá debido a la integración desactivada con el sistema Bitfactura.",
  default_price: "100,00 €",
  added_location_notification: "Ubicación añadida",
  updated_location_notification: "Ubicación actualizada",
  add_location_title: "Agregar ubicación",
  update_location_title: "Editar ubicación",
  add_locations_with_plus:
    'No se encontraron ubicaciones. Añade una ubicación haciendo clic en "+" en el lado derecho.',
  add_availability_schedule_conditions_title: "Agregar condiciones",
  edit_availability_schedule_conditions_title: "Cambiar condiciones",
  add_availability_schedule_exception_title: "Agregar excepción",
  edit_availability_schedule_exception_title: "Cambiar excepción",
  availability_schedule_conditions_description:
    "Las condiciones permiten asignar servicios o ubicaciones a rangos de tiempo específicos. Esto significa que cuando un cliente navega por el calendario de disponibilidad, el sistema automáticamente verifica la disponibilidad considerando criterios adicionales como los servicios o ubicaciones seleccionados.",
  timezone: "Zona horaria",
  copy_hours_button_title: "Copiar horas",
  copy_hours_from_monday_item: "Copiar horas de lunes",
  copy_hours_from_tuesday_item: "Copiar horas de martes",
  copy_hours_from_wednesday_item: "Copiar horas de miércoles",
  copy_hours_from_thursday_item: "Copiar horas de jueves",
  copy_hours_from_friday_item: "Copiar horas de viernes",
  copy_hours_from_saturday_item: "Copiar horas de sábado",
  copy_hours_from_sunday_item: "Copiar horas de domingo",
  add_hours_range_button_tooltip:
    "Añadir un nuevo rango de horas que puedes asignar a ubicaciones o servicios específicos.",
  add_hours_interval_button_tooltip:
    "Crear un nuevo intervalo de tiempo dentro de un rango de horas existente.",
  delete_date_range_warning_title: "Eliminar rango de fechas",
  delete_date_range_warning_description:
    "¿Estás seguro de que quieres eliminar este rango de fechas? Esto desactivará las excepciones para los días seleccionados.",
  add_new_date_range_button_tooltip:
    "Añadir excepciones para los días laborables para modificar el horario de trabajo estándar.",
  exceptions_date_range_title:
    "Excepciones - fechas que sobrescriben los días laborables",
  weekday_rules_title: "Semana laboral estándar",
  exceptions_date_range_info:
    "Al añadir fechas de excepción, puedes establecer un horario especial para días específicos, ignorando así las configuraciones estándar para esos días.",
  availability_schedule_assign_to_employees_info:
    "Recuerda que cada empleado puede tener solo un horario de disponibilidad asignado. Asignar un nuevo horario de disponibilidad a los empleados listados a continuación reemplazará sus horarios actuales.",
  availability_schedule_assign_to_services_info:
    "Recuerda que solo se puede asignar un horario de disponibilidad a cada servicio. Los servicios pueden utilizar los horarios de disponibilidad asignados a los empleados o tener su propio horario dedicado. Asignar el horario de disponibilidad seleccionado a los servicios listados a continuación sobrescribirá sus configuraciones de horario actuales. Estos servicios utilizarán el horario de disponibilidad seleccionado.",
  other_settings_title: "Otros ajustes",
  subscription_status_info:
    "Una suscripción activa permite a los clientes comprar y usar libremente, mientras que una suscripción inactiva impide realizar nuevas compras. Los clientes que previamente adquirieron una suscripción aún pueden usarla sin cambios a pesar de su desactivación, pero no tendrán la opción de comprar una nueva.",
  subscription_stop_automatic_payments_checkbox_title:
    "Detener el cobro de pagos y terminar la suscripción después de un tiempo especificado",
  subscription_stop_automatic_payments_after:
    "Terminar el cobro de pagos después de",
  subscription_allow_users_to_cancel_checkbox_title:
    "Permitir a los clientes cancelar suscripciones activas por sí mismos en el panel de cliente",
  subscription_prices_warning:
    "Recuerda que el monto de la suscripción y cualquier plazo de finalización no pueden cambiarse después de su creación. Si deseas modificar estos parámetros, debes crear una nueva suscripción.",
  cancel_subscription_dialog_title: "Cancelación de la suscripción del cliente",
  cancel_single_subscription_dialog_question:
    "¿Estás seguro de que quieres cancelar la suscripción seleccionada?",
  cancel_multiple_subscription_dialog_question:
    "¿Estás seguro de que quieres cancelar las suscripciones seleccionadas?",
  cancel_subscription_immediately_checkbox_title:
    "Marca para cancelar inmediatamente, sin esperar al final del periodo.",
  cancel_single_subscription_confirm_button: "Cancelar suscripción",
  cancel_multiple_subscription_confirm_button: "Cancelar suscripciones",
  selected_ids_label: "Id seleccionados",
  service_location_online: "Online",
  service_location_phone: "Teléfono",
  service_location_name: "Nombre",
  wb_select_locations_title_label: "Texto al seleccionar ubicación",
  wb_select_services_title_label: "Texto al seleccionar servicio",
  wb_select_employees_title_label: "Texto al seleccionar empleado",
  wb_select_locations_title_example_1: "Elige una ubicación,",
  wb_select_locations_title_example_2: "para ver los servicios disponibles",
  wb_any_employee_enabled:
    "Seleccionar automáticamente un empleado al azar, omitiendo el paso de selección",
  wb_show_expand_collapse_button: 'Mostrar botón "Mostrar más horas"',
  wb_show_booked_slots: "Mostrar citas reservadas en el calendario",
  wb_initial_location_select:
    "Seleccionar automáticamente la primera ubicación",
  show_more_hours_button: "Mostrar más horas",
  add_another_button_title: "Añadir otro",
  wb_dummy_text_employee_description:
    "Esta es una descripción de ejemplo de un empleado. Puedes configurarla en el panel de administración de Calendesk al editar la cuenta del empleado. Puedes elegir cualquier texto para diferentes empleados.",
  any_person_label: "Cualquier persona",
  selected_time_slots_label: "Citas seleccionadas",
  logs_diff_phone_id: "ID del teléfono",
  logs_diff_address_id: "ID de la dirección",
};
